$primaryColor: #212121;
$primaryLightColor: #484848;
$primaryULighterColor: #888888;
$primaryDarkColor: #000000;
$primaryTextColor: #ffffff;

$secondaryColor: #fdd835;
$secondaryLightColor: #ffff6b;
$secondaryDarkColor: #c6a700;
$secondaryTextColor: #000000;

$primaryColor: #fdd835;
$primaryLightColor: #ffff6b;
$primaryDarkColor: #c6a700;
$primaryTextColor: #000000;

$secondaryColor: #212121;
$secondaryLightColor: #484848;
$secondaryDarkColor: #000000;
$secondaryTextColor: #fdd835;

/*
$primaryColor: #7a1ba7;
$primaryLightColor: #ad50d9;
$primaryDarkColor: #480077;
$primaryTextColor: #000000;

$secondaryColor: #212121;
$secondaryLightColor: #484848;
$secondaryDarkColor: #000000;
$secondaryTextColor: #7a1ba7;
*/

$tertiaryColor: #7a1ba7;
$tertiaryLightColor: #ad50d9;
$tertiaryDarkColor: #480077;
$tertiaryTextColor: #ffffff;

$primaryBackgroundColor: #101010;

:export {
  primaryColor: $primaryColor;
  secondaryColor: $secondaryColor;
}
