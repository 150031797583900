@import url(https://fonts.googleapis.com/css?family=Audiowide);
@import url(https://fonts.googleapis.com/css?family=Bubblegum+Sans);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
$primaryColor: #7a1ba7;
$primaryLightColor: #ad50d9;
$primaryDarkColor: #480077;
$primaryTextColor: #000000;

$secondaryColor: #212121;
$secondaryLightColor: #484848;
$secondaryDarkColor: #000000;
$secondaryTextColor: #7a1ba7;
*/

body {
  background-color: #212121;
  color: #fdd835; }

.primary-color {
  color: #fdd835; }

.secondary-color {
  color: #212121; }

.logo-text {
  font-family: 'Permanent Marker'; }

.logo-text {
  font-family: 'Audiowide';
  font-weight: 400;
  font-size: 48px;
  text-decoration: none;
  /*
  &:hover {
    color: $secondaryColor;
  }
  */ }

.logo-symbol-text {
  font-family: 'Bubblegum Sans';
  text-decoration: none; }

.home {
  text-align: center;
  margin-top: 48px; }
  .home .logo-symbol-text {
    font-size: 88px; }

