@import url('https://fonts.googleapis.com/css?family=Audiowide');
@import url('https://fonts.googleapis.com/css?family=Bubblegum+Sans');
@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');

body {
  background-color: $secondaryColor;
  color: $secondaryTextColor;

  // background-color: $secondaryTextColor;
  // color: $secondaryColor;
}

.primary-color {
  color: $primaryColor;
  // color: $secondaryColor;
}

.secondary-color {
  color: $secondaryColor;
}

.logo-text {
  font-family: 'Permanent Marker';
}

.logo-text {
  font-family: 'Audiowide';
  font-weight: 400;
  font-size: 48px;
  text-decoration: none;
  /*
  &:hover {
    color: $secondaryColor;
  }
  */
}

.logo-symbol-text {
  font-family: 'Bubblegum Sans';
  text-decoration: none;
}

.home {
  text-align: center;
  margin-top: 48px;

  .logo-symbol-text {
    font-size: 88px;
  }
}
